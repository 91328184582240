
import { Options } from "vue-class-component"
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import CloudObject from "@/scripts-core/cloud/CloudObject";
import { Support } from "possibleme-db";
import { Prop } from "vue-property-decorator"
import * as dateutil from "possibleme-db/lib/util/DateHelper"
import ActionDialog, { ActionHandler } from "@/components/phoenix/features/ActionDialog.vue"
import MeDone from "../helper/MeDone.vue"
import MeInvoice from "../helper/MeInvoice.vue"



@Options({
    components :{
        ActionDialog,
        MeDone,
        MeInvoice
    }
})
export default class ModelEvent extends FireComponent {
    @Prop()
    support! : CloudObject<Support>
    util = dateutil;

    componentClass = () => "possible-model-event"
    onUserReady(): void {
        return;
    }
    
    displayDate(){
        return dateutil.displayDate(this.support.value(this).dateStart)
    }
}
