
import FireComponent from "@/components/phoenix/core/FireComponent.vue"
import { ActionHandler } from "@/components/phoenix/features/ActionDialog.vue"
import CloudObject from "@/scripts-core/cloud/CloudObject"
import { Support } from "possibleme-db"
import { Options } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import MeDone from "./MeDone.vue"
import ActionDialog from "../../phoenix/features/ActionDialog.vue"

@Options({
  components : {
      MeDone,
      ActionDialog
  },
})
export default class MeInvoice extends FireComponent {
    
    @Prop()
    support! : CloudObject<Support>

    paidAction! : ActionHandler
    claimAction! : ActionHandler

    showInvoice = false;
    inpInvoice = ""

    isPaid = false
    isClaimed = false;

    wasPaid = false;

    overrideClaim = false;
    overridePaid = false;

    mounted(){
        this.isPaid = this.support.value(this).isPaid
        this.isClaimed = this.support.value(this).isClaimed

        this.wasPaid = this.support.object.isPaid
    }
    //fired when 'Paid' checkbox changes state
    onPaid(){
        if(this.overridePaid)
            return (this.overridePaid = false)

        if(this.isPaid){
            this.showInvoice = true;
        }
        else {
            this.isPaid = true;
            this.paidAction()
        }
    }
    //fired when 'claim' checkbox changes state
    setClaimed(state : boolean){
        this.overrideClaim = true;
        this.isClaimed = state;
        this.support.value(this).isClaimed = state;
    }
    payInvoice(invoiceNum : string){
        this.overridePaid = true;
        this.isPaid = true;
        this.support.value(this).isPaid = true;
        this.support.value(this).invoiceNumber = invoiceNum;
        this.showInvoice = false;
    }

    onClaim(){
        if(this.overrideClaim)
            return (this.overrideClaim = false);

        
        if(this.isClaimed){
            this.support.value(this).isClaimed = true;
        }
        else {
            this.claimAction();
            this.isClaimed = true;
        }
    }


    onUserReady(): void {
        return
    }
    componentClass(): string {
        return "MeInvoice"
    }

    submitInvoice() {
        this.payInvoice(this.inpInvoice);
    }
    
    onUnpay(){
        this.overridePaid = true;
        this.isPaid = false;
        this.support.value(this).isPaid = false
        this.support.value(this).invoiceNumber = ""
        this.showInvoice = false;
    }
    onUnclaim(){
        this.setClaimed(false);
    }
    onPaidAction(handler : ActionHandler){
        this.paidAction = handler;
    }
    onClaimAction(handler : ActionHandler) {
        this.claimAction = handler;
    }

}
