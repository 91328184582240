
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import CloudObject from "@/scripts-core/cloud/CloudObject";
import { Goals, SupportIdea } from "possibleme-db"
import { Prop } from "vue-property-decorator"
import PhoenixPopup, { PopupToggler } from "@/components/phoenix/ObjectPopup.vue"
import { Options } from "vue-class-component"
import { getGoals } from "@/scripts-possibleme/client/PossibleClient"
import { putReview } from "possibleme-db/lib/Util"
import LightArray from "@/scripts-core/util/LightArray";
import PhoenixSubmit from "@/components/phoenix/PhoenixSubmit.vue"
import MeButton from "../helper/MeButton.vue"
import { sendAlertMessage } from "@/components/phoenix/PhoenixComponent";

@Options({
    components :{
        PhoenixPopup,
        PhoenixSubmit,
        MeButton
    }
})
export default class ModelReview extends FireComponent {
    reviewSize = "review-size"

    @Prop()
    review! : CloudObject<SupportIdea>
    toggler! : PopupToggler
    goals : CloudObject<Goals> | null = null;
    checkArray = new LightArray<string>();

    onToggler (toggler : PopupToggler){
        this.toggler = toggler;
    }
    displayGoal(item : any){
      return item.name
    }
    clkSubmit(){
        const array = this.checkArray.toArray();
        if(this.checkArray.size() == 0)
            return sendAlertMessage("Invalid Review", "User must select at least 1 goal against this support.");
        putReview(this.review.value(this), array);   
        this.toggler(false, "done")
    }

    
    componentClass = () => "possible-model-review"
    onUserReady(uid: string|null): void {
      
    
      this.goals = getGoals(this);
      //const aim = computeGoals(localGoals)
    }
    onSelect(checked : boolean, index : string){
      if(checked) this.checkArray.push(index);
      else this.checkArray.eject(index);
    }
    clkReview(){
      this.toggler?.(true);
    }
    
}
