
import { Options } from "vue-class-component"
import ModelActivity from "@/components/possibleme/model/ModelActivity.vue"
import ModelReview from "@/components/possibleme/model/ModelReview.vue"
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import CloudObject from "@/scripts-core/cloud/CloudObject";
import * as VClient from "@/scripts-possibleme/client/PossibleClient"
import { Support, SupportIdea } from "possibleme-db";
import PhoenixPopup, { PopupToggler } from "@/components/phoenix/ObjectPopup.vue"
import PlanBanner from "@/components/possibleme/PlanBanner.vue"
import { logEventPageView, PAGE_ACTIVITY } from "@/possibleme/PossibleAnalytics";

@Options({
    components :{
        ModelActivity,
        ModelReview,
        PhoenixPopup,
        PlanBanner
    }
})
export default class VueActivity extends FireComponent {
    

    activitySupports : CloudObject<Support>[] = []
    toreview : CloudObject<SupportIdea>[] = []

    componentClass = ()=> "possiblevue-activity"

    mounted() {
        logEventPageView(PAGE_ACTIVITY)
    }

    onUserReady(uid: string|null): void {
        VClient.subscribeCurrentSupports(this.getContext(), this.onSupports)
        VClient.subscribeIdeas(this.getContext(), this.onReviews);
    }
    onReviews(reviews : CloudObject<SupportIdea>[] | null){
        if(!reviews)return;
            this.toreview = reviews.filter(cmp => {
                return cmp.value(this).showReview == true
            })
    }
    onSupports(supports : CloudObject<Support>[] | null){
        if(!supports) return;
        const now = new Date();
        this.activitySupports = supports.filter(item =>{
            const value = item.value(this);
            return (!value.isPaid || !value.isClaimed) && value.dateStart <= now.getTime();
        });

        this.activitySupports = this.activitySupports.sort((a ,b)=>{
            return a.value(this).dateStart - b.value(this).dateStart;
        })
       // 
    }
}
