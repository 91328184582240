<template>
    <div class='bg-white pb-4'>
        <PlanBanner></PlanBanner>
        <div class='indent compose main-frame'>
            <div class='main-frame--header'>
            <h2>NDIS Plan Notifications</h2>
            <p class='capsize'>
                Review your supports against your NDIS goals regularly to help keep track of your plan's progression.
            </p>
            </div>
            <div class='content-activity'>
                <div>
                    <h2>Review Actions</h2>
                    <div class='flex mobile-flex' v-if="toreview.length == 0">
                        <div class='auto--margin material-icons'>error</div>
                        <div class='shift-right__shrink'>No reviews that need to be reviewed</div>
                    </div>
                    <ModelReview v-for="item in toreview" :key="`torev:${item.id()}`" :review="item">
                    </ModelReview>
                </div>
                <div>
                    <h2>Payment Actions</h2>
                    <div class='compose--flex' v-if="activitySupports.length == 0">
                        <div class='auto--margin material-icons'>error</div>
                        <div class='shift-right__shrink'>No supports that need to be paid or claimed</div>
                    </div>
                    <ModelActivity v-for="item in activitySupports" :key="`activity:${item.id()}`" :support="item">
                    </ModelActivity>
                </div>
            </div>
        </div> 
    </div>
</template>

<script lang="ts">
import { Options } from "vue-class-component"
import ModelActivity from "@/components/possibleme/model/ModelActivity.vue"
import ModelReview from "@/components/possibleme/model/ModelReview.vue"
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import CloudObject from "@/scripts-core/cloud/CloudObject";
import * as VClient from "@/scripts-possibleme/client/PossibleClient"
import { Support, SupportIdea } from "possibleme-db";
import PhoenixPopup, { PopupToggler } from "@/components/phoenix/ObjectPopup.vue"
import PlanBanner from "@/components/possibleme/PlanBanner.vue"
import { logEventPageView, PAGE_ACTIVITY } from "@/possibleme/PossibleAnalytics";

@Options({
    components :{
        ModelActivity,
        ModelReview,
        PhoenixPopup,
        PlanBanner
    }
})
export default class VueActivity extends FireComponent {
    

    activitySupports : CloudObject<Support>[] = []
    toreview : CloudObject<SupportIdea>[] = []

    componentClass = ()=> "possiblevue-activity"

    mounted() {
        logEventPageView(PAGE_ACTIVITY)
    }

    onUserReady(uid: string|null): void {
        VClient.subscribeCurrentSupports(this.getContext(), this.onSupports)
        VClient.subscribeIdeas(this.getContext(), this.onReviews);
    }
    onReviews(reviews : CloudObject<SupportIdea>[] | null){
        if(!reviews)return;
            this.toreview = reviews.filter(cmp => {
                return cmp.value(this).showReview == true
            })
    }
    onSupports(supports : CloudObject<Support>[] | null){
        if(!supports) return;
        const now = new Date();
        this.activitySupports = supports.filter(item =>{
            const value = item.value(this);
            return (!value.isPaid || !value.isClaimed) && value.dateStart <= now.getTime();
        });

        this.activitySupports = this.activitySupports.sort((a ,b)=>{
            return a.value(this).dateStart - b.value(this).dateStart;
        })
       // 
    }
}
</script>

<style scoped>
.content-activity {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}
.content-activity > * {
    flex-basis: 500px;
    
}
.content-activity:first {
    max-width: 200px;
}
h2 {
   margin-bottom: 1rem; 
}

</style>