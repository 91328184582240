<template>
    <div class='review-content'>
        <div class='title-span'>
            <div class='review-title'>{{review.object.name}}</div>

            <MeButton :labelText="'Review'"  @click="clkReview"/>
        </div>
        <PhoenixPopup :aclass="reviewSize" :title="`Review Goals`"  @toggler="onToggler">
            <template  v-slot:body>
              <div class='dialog-form '>
                    <div class='dialog--child popup-review'>
                        <p class='review-detail'>
                            In the past 5 sessions or 2 months, this support has helped you in which of your NDIS goal(s)?
                        </p>
                        <h2>{{review.object.name}}</h2>
                        
                        <div v-for="(item, index) in goals?.object.goalList" :key="`igoal${index}`">  
                            <div class='review-item' v-if="item.shown">
                                <input class='auto-margin'  @change="onSelect($event.target.checked, index, value)" type="checkbox" :id="`${index}`" :value="`${item.name}`" :name="`${item.name}`">
                                <span class='auti-margin shift-right'> {{item.name}}</span>
                            </div>
                        </div>
                    </div>
                    <PhoenixSubmit class='fill' :labelText="`Submit Review`" @click="clkSubmit"></PhoenixSubmit>
                </div>    
            </template>
        </PhoenixPopup> 
    </div>
</template>

<script lang="ts">
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import CloudObject from "@/scripts-core/cloud/CloudObject";
import { Goals, SupportIdea } from "possibleme-db"
import { Prop } from "vue-property-decorator"
import PhoenixPopup, { PopupToggler } from "@/components/phoenix/ObjectPopup.vue"
import { Options } from "vue-class-component"
import { getGoals } from "@/scripts-possibleme/client/PossibleClient"
import { putReview } from "possibleme-db/lib/Util"
import LightArray from "@/scripts-core/util/LightArray";
import PhoenixSubmit from "@/components/phoenix/PhoenixSubmit.vue"
import MeButton from "../helper/MeButton.vue"
import { sendAlertMessage } from "@/components/phoenix/PhoenixComponent";

@Options({
    components :{
        PhoenixPopup,
        PhoenixSubmit,
        MeButton
    }
})
export default class ModelReview extends FireComponent {
    reviewSize = "review-size"

    @Prop()
    review! : CloudObject<SupportIdea>
    toggler! : PopupToggler
    goals : CloudObject<Goals> | null = null;
    checkArray = new LightArray<string>();

    onToggler (toggler : PopupToggler){
        this.toggler = toggler;
    }
    displayGoal(item : any){
      return item.name
    }
    clkSubmit(){
        const array = this.checkArray.toArray();
        if(this.checkArray.size() == 0)
            return sendAlertMessage("Invalid Review", "User must select at least 1 goal against this support.");
        putReview(this.review.value(this), array);   
        this.toggler(false, "done")
    }

    
    componentClass = () => "possible-model-review"
    onUserReady(uid: string|null): void {
      
    
      this.goals = getGoals(this);
      //const aim = computeGoals(localGoals)
    }
    onSelect(checked : boolean, index : string){
      if(checked) this.checkArray.push(index);
      else this.checkArray.eject(index);
    }
    clkReview(){
      this.toggler?.(true);
    }
    
}
</script>

<style scoped>
.review-item {
    
    padding-top:.5rem;
    padding-bottom:.5rem;
}

.review-title {
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
}
.review-content {
    padding-top: var(--stm--margin__shrink);
    padding-bottom : var(--stm--margin__shrink);
}
.popup-review {
    min-height: 300px;
}
.title-span{
    display: flex;
    justify-content: space-between;

}
.submit-review {
    width: 100%;
    
}
.review-detail {
    width: 100%;
    font-style: italic;
}

</style>
<style>
.review-size{
    max-width:700px;
}
</style>
    